@import '~bootstrap/scss/functions';

// First we specify the default variables (so they can be overridden in specific themes)
$primary: #FF9900 !default;
$secondary: #0099FF !default;
$success: #28a745 !default;
$danger: #dc3545 !default;
$warning: #e78100 !default;
$info: #17a2b8 !default;
$default: #FFF !default;
$factory-lab: rgb(159, 12, 180);
$admin: #FFFF00;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-border: #e0e0e0 !default;
$black: #000 !default;

$fancy-component-hover-bg: #e9edf4;
$fancy-component-active-bg: #dee6f0;

$enable-responsive-font-sizes: true !default;
$enable-shadows: true !default;

$grid-gutter-width: 1.5rem !default;
$spacer: 1.5rem !default;

$root-font-size: 13px !default;
$font-family-base: apple-system, BlinkMacSystemFont,
"Segoe UI", Roboto, "Noto Sans",
Ubuntu, Cantarell, "Helvetica Neue",
sans-serif, "Apple Color Emoji",
"Segoe UI Emoji", "Segoe UI Symbol",
"Noto Color Emoji";

$navbar-dark-color: #FFF !default;
$navbar-dark-hover-color: #FFF !default;

$navbar-nav-link-padding-x: 1rem;
$nav-link-padding-y: .875rem;

$dropdown-padding-y: .5rem !default;
$dropdown-item-padding-y: .375rem !default;

$popover-font-size: 1rem;

$label-margin-bottom: .125rem !default;

$body-bg: $gray-100;
$thumbnail-bg: #white;
$body-color: $gray-900;

$card-cap-bg: rgba($black, .1);

// After we defined the default bootstrap variable,s import the other variables.
@import '~bootstrap/scss/variables';

$dropdown-link-hover-bg: $gray-200;
$dropdown-link-active-bg: $gray-300;
$dropdown-link-active-color: color-yiq($gray-300);

// When the bootstrap variables are imported, specify all the other colors as well.

$primary-dark: darken($primary, 15%) !default;
$primary-light: lighten($primary, 15%) !default;;
$primary-lighter: lighten($primary, 50%) !default;;
$primary-lightest: lighten($primary, 62.5%) !default;;

$brand-add: #21A63D !default;
$brand-delete: #D4667A !default;
$brand-nav: #6c6b6b !default;

$brand-measurement: #5cb85c !default;
$brand-measurement-border: darken($brand-measurement, 5%) !default;

$brand-installation-existing: #ff5c22 !default;
$brand-installation-existing-border: darken($brand-installation-existing, 5%) !default;

$brand-installation: $warning !default;
$brand-installation-border: darken($warning, 5%) !default;

$brand-action: #d55e96 !default;
$brand-action-hover: $primary !default;

$excel: #1d6f42;

$stats-ok: #7cb342 !default;
$stats-not-ok: #e53935 !default;
$stats-ignored: #c0c0c0 !default;
$stats-not-ok-but-handled: #fb8c00 !default;
$stats-not-ok-transparent: rgba(229, 57, 53, .15) !default;
$stats-pending: #3da2b8 !default;

$animation-cubic: cubic-bezier(0.4, 0.0, 0.2, 1) !default;

$theme-colors: map_merge(
                (
                        "primary-dark": $primary-dark,
                        "default": $default,
                        "add": $brand-add,
                        "delete": $brand-delete,
                        "nav": $brand-nav,
                        "measurement-existing": $brand-measurement,
                        "installation-existing": $brand-installation-existing,
                        "installation": $brand-installation,
                        "gray": $gray-800,
                        "gray-400": $gray-400,
                        "factory-lab": $factory-lab,
                        "excel": $excel,
                        "admin": $admin,
                ),
                $theme-colors
);

$stats-colors: (
        'ok': $stats-ok,
        'not-ok': $stats-not-ok,
        'ignored': $stats-ignored,
        'not-ok-but-handled': $stats-not-ok-but-handled,
        'not-ok-transparent': $stats-not-ok-transparent,
        'pending': $stats-pending,
);

$h7-font-size: $font-size-base * 0.875 !default;

$text-sizes: () !default;
$text-sizes: map_merge(
                (
                        1: $h1-font-size,
                        2: $h2-font-size,
                        3: $h3-font-size,
                        4: $h4-font-size,
                        5: $h5-font-size,
                        6: $h6-font-size,
                        7: $h7-font-size,
                ),
                $text-sizes
)
