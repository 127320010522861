@import "../../../../scss/theme/base/variables";

.error-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .error-gradient {
    width: 100%;
    height: 100%;

    background-color: theme-color-level("primary", -7);

    animation: pulse 10s infinite;
  }
}

.error-container {

  &[data-code] {
    margin-top: 100px;
    position: relative;

    &::before {
      z-index: 0;
      content: attr(data-code);
      font-size: 10rem;
      color: $gray-200;
      position: absolute;
      left: -4rem;
      top: -8rem;
    }
  }
}